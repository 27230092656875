import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";
import NewsList from "../components/news-list";
import TagsList from "../components/tags-list";

export default ({ data }) => (
  <Layout>
    <SEO
      title="News"
      keywords={[
        "news",
        "electrical",
        "Philippines",
        "design",
        "build",
        "system",
        "supply",
        "troubleshoot",
        "repair",
        "equipments",
        "test",
        "manage",
        "bms"
      ]}
    />
    <div className="gradient-half-blue-v1">
      <HeroBanner
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      >
        <h1 className="text-white">Latest News</h1>
        <p className="text-white lead">
          Our duty towards you is to share our experience we're reaching in our
          work path with you.
        </p>
      </HeroBanner>
    </div>

    <div className="container space-2 space-3-bottom--lg">
      <div className="row">
        <div className="col-lg-9 mb-9 mb-lg-0">
          <NewsList />
        </div>
        <div className="col-lg-3">
          {" "}
          <TagsList />{" "}
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    bgDesktopWide: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
