import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

class NewsList extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allKdccmsNews;

    if (!posts || posts.length === 0) {
      return <h3>Coming soon...</h3>;
    }

    return (
      <div className="card-mb card-sm-columns card-sm-2-count">
        {posts.map(({ node: post }) => (
          <article className="card mb-3" key={post.id}>
            {post.bannerImage ? (
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.bannerImage,
                  alt: `featured image thumbnail for post ${post.title}`,
                  className: "card-img-top"
                }}
              />
            ) : null}
            {/* <img className="card-img-top" src="../../assets/img/500x250/img11.jpg" alt="Image Description" /> */}
            <div className="card-body p-5">
              <small className="d-block text-muted mb-2">
                {post.publishDate}
              </small>
              <h3 className="h5">
                <Link className="button" to={`/news/${post.slug}`}>
                  {post.title}
                </Link>
              </h3>
              <p>{post.description}</p>
            </div>
          </article>
        ))}
      </div>
    );
  }
}

NewsList.propTypes = {
  data: PropTypes.shape({
    allKdccmsNews: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query NewsListQuery {
        allKdccmsNews(sort: { order: DESC, fields: [publishDate] }) {
          edges {
            node {
              id
              slug
              title
              publishDate(formatString: "MMMM DD, YYYY")
              description
              bannerImage {
                childImageSharp {
                  fluid(maxWidth: 403, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewsList data={data} count={count} />}
  />
);
